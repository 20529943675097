.f-7x
  font-size: calc( var( --base ) * 7 )

.f-14x
  font-size: calc( var( --base ) * 14 )

.f-21x
  font-size: calc( var( --base ) * 21 )

.f-28x
  font-size: calc( var( --base ) * 28 )

.f-35x
  font-size: calc( var( --base ) * 35 )

.f-42x
  font-size: calc( var( --base ) * 42 )

.f-49x
  font-size: calc( var( --base ) * 49 )

.f-56x
  font-size: calc( var( --base ) * 56 )

.f-63x
  font-size: calc( var( --base ) * 63 )

.f-70x
  font-size: calc( var( --base ) * 70 )
