$ss: calc( var( --square-size ) * 0.7 )

.owner
  background: blue
  height: $ss
  width: $ss
  border-radius: 50%

  display: flex
  justify-content: center
  align-items: center

  text-transform: uppercase
  font-size: calc( $ss * 0.42 )

  &:nth-child( 1 )
    background: #b7393e

  &:nth-child( 2n )
    background: #2fc1b2

  &:nth-child( 3n )
    background: #1c7cbc

  &:nth-child( 4n )
    background: #dc8e65

  &:nth-child( 5n )
    background: #da6079
