.breadcrumb
  gap: calc( var( --gap ) * 0.49 )

  a
    text-decoration: none

    &:hover
      text-decoration: underline

  ul
    display: flex
    gap: calc( var( --gap ) * 0.49 )

    li
      & + li

        &:before
          content: '>'
          margin-right: calc( var( --gap ) * 0.49 )
          font-size: 70%
