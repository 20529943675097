.milestone
  .metadata
    padding: var( --gap )
    line-height: calc( var( --square-size ) * 0.7 )

    gap: var( --gap )

    // .status
      background: red
      padding: 0 calc( var( --gap ) * 0.7 )
      border-radius: calc( var( --square-size ) * 0.7 )

      &.open
        background: #c3e6cd
        color: #24663b

      &.closed
        background: #fdd4cd
        color: #ae1800

    .title
      h1
        font-size: 140%

  .tabs
    margin: 0 var( --gap )
    height: var( --square-size )
    display: flex
    gap: var( --gap )

    button
      display: flex
      align-items: center
      gap: calc( var( --gap ) * 0.42 )

      border-bottom: 2px solid transparent

      svg
        font-size: calc( var( --square-size ) * 0.49 )

      &.active
        border-bottom-color: #ea8f98
