.task-detail

  flex: 1
  display: flex
  flex-direction: column

  flex: 1
  display: flex

  header
    &.meta
      padding: var( --gap )
      display: flex
      flex-direction: column
      gap: calc( var( --base ) * 0.7 )

      h1
        font-size: 140%

  section
    flex: 1
    display: flex
    overflow-y: auto

    a
      color: white

    .main
      flex: 2
      display: flex
      flex-direction: column

      .comments
        flex: 1
        display: flex
        flex-direction: column
        overflow-y: auto

        padding: var( --gap )
        // gap: var( --gap )

        .comment
          display: flex
          gap: calc( var( --gap ) * 0.49 )
          padding: calc( var( --gap ) * 0.49 )
          background: var( --color-transparency-black )

          .icon
            font-size: 140%
            padding: 0 calc( var( --gap ) * 0.49 )

          .body
            flex: 1
            display: flex
            flex-direction: column
            gap: calc( var( --gap ) * 0.49 )

            .meta
              display: flex
              gap: calc( var( --gap ) * 0.49 )

            .content

          &:nth-child( even )
            background: var( --color-transparency-white )

      footer

        &.leave-comment
          padding: var( --gap )
          display: flex
          gap: var( --gap )

          .form
            flex: 1
            display: flex
            flex-direction: column
            gap: var( --gap )

            textarea,
            button
              background: #293647
              font-size: calc( var( --square-size ) * 0.42 )
              padding: 0 calc( var( --gap ) * 0.5 )

            textarea
              padding: calc( var( --gap ) * 0.35 ) calc( var( --gap ) * 0.5 )

            button
              padding: 0 var( --gap )

            .actions
              align-self: flex-end
              display: flex
              gap: var( --gap )

              button
                height: var( --square-size )
                display: flex
                gap: var( --1x )
                justify-content: center
                align-items: center

    .aside
      flex: 1
