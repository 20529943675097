.bar

  display: flex
  align-items: center
  min-height: var( --square-size )

  background: #293647
  color: white

  font-size: calc( var( --square-size ) * 0.56 )

  h1,
  nav
    padding: 0 var( --gap )

  a
    color: white

  button
    min-height: var( --square-size )
    min-width: var( --square-size )

    display: flex
    justify-content: center
    align-items: center

    svg
      font-size: calc( var( --square-size ) * 0.56 )

  img
    height: calc( var( --square-size ) * 0.7 )
    align-self: center
