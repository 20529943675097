.status
  background: red
  padding: 0 calc( var( --gap ) * 0.7 )
  border-radius: calc( var( --square-size ) * 0.7 )

  &.open
    background: #c3e6cd
    color: #24663b

  &.closed
    background: #fdd4cd
    color: #ae1800

  &.working
    background: #99bcce
    color: #33799c

  &.paused
    background: #ffeebe
    color: #b68613

  &.archived
    background: #5d7ca7
    color: #c6d1df
