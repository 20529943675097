$gap: calc( var( --gap ) * 0.49 )
$square: calc( var( --square-size ) * 2.1 )

.profile
  flex: 1
  display: flex
  flex-direction: column

  gap: var( --gap )

  header
    display: flex
    flex-direction: column
    gap: var( --gap )
    padding: var( --gap )

    .meta
      display: flex
      gap: var( --gap )

      .identity
        flex: 1
        display: flex
        gap: var( --gap )

        .photo
          background: #aaa
          width: $square
          height: $square
          display: flex
          justify-content: center
          align-items: center
          border-radius: 50%

          img
            width: calc( $square - var( --gap ) )
            border-radius: 50%

          .placeholder
            font-size: calc( $square / 1.7 )

        & > div
          display: flex
          flex-direction: column
          justify-content: flex-end

          h1
            font-size: 140%
            font-weight: bold

      .counters
        flex: 1
        display: flex
        justify-content: flex-end
        align-items: flex-end
        gap: $gap

        button
          height: var( --square-size )

          padding: 0 var( --gap )

          font-size: calc( var( --square-size ) * 0.42 )

          display: flex
          align-items: center

          border-bottom: 2px solid transparent

          svg
            margin-right: calc( var( --gap ) * 0.5 )
            font-size: calc( var( --square-size ) * 0.49 )

          &.active
            border-bottom-color: #ea8f98

    .progress-wrap

  section.task-list
    a
      color: white

    .task-item
      display: flex
      padding: var( --gap )

      nav
        flex: 3
        ul
          display: flex
          gap: $gap

          li
            & + li
              &:before
                content: '>'
                margin-right: $gap

      .assignees
        flex: 1
        display: flex
        gap: $gap
        justify-content: flex-end

      &:hover
        background: var( --color-transparency-white )
