.hs
  display: flex
  flex-direction: column

  &.reverse
    flex-direction: column-reverse

.vs
  display: flex
  flex-direction: row

  &.reverse
    flex-direction: row-reverse

.flex-end
  justify-content: flex-end

.justify-center
  justify-content: center

.align-center
  align-items: center

.space-between
  justify-content: space-between

.wrap
  flex-wrap: wrap

.zero
  flex: 0

.one
  flex: 1

.two
  flex: 2

.three
  flex: 3

.four
  flex: 4

.five
  flex: 5

.six
  flex: 6

.seven
  flex: 7

.gap
  gap: var( --gap )

.padding
  padding: var( --gap )

.padding-x
  padding: 0 var( --gap )

.padding-y
  padding: var( --gap ) 0

.w-10x
  width: calc( var( --base ) * 10 )

.w-14x
  width: calc( var( --base ) * 14 )

.w-21x
  width: calc( var( --base ) * 21 )

.w-28x
  width: calc( var( --base ) * 28 )

.w-35x
  width: calc( var( --base ) * 35 )

.w-42x
  width: calc( var( --base ) * 42 )

.w-49x
  width: calc( var( --base ) * 49 )

.w-56x
  width: calc( var( --base ) * 56 )

.w-63x
  width: calc( var( --base ) * 63 )

.w-70x
  width: calc( var( --base ) * 70 )

.w-200x
  width: 200px

.w-300x
  width: 300px

.w-400x
  width: 400px

.overflow-x
  overflow-x: auto

.overflow-y
  overflow-y: auto

.overflow-hidden
  overflow: hidden

.hidden
  display: none
