.danger-bg,
.error-bg
  background: #ff4d42

.danger-color,
.error-color
  color: #ff4d42

.warn-bg
  background: #e1c247

.warn-color
  color: #e1c247

.info-bg
  background: #80bcfc

.info-color
  color: #80bcfc

.success-bg
  background: #a1e3a9

.success-color
  color: #a1e3a9
