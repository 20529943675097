\:root
  --base: 7px

  --1x: var( --base )
  --2x: calc( var( --base ) * 2 )
  --3x: calc( var( --base ) * 3 )
  --4x: calc( var( --base ) * 4 )
  --5x: calc( var( --base ) * 5 )
  --6x: calc( var( --base ) * 6 )
  --7x: calc( var( --base ) * 7 )

  --aside-size: calc( var( --base ) * 42 )
  --header-size: var( --7x )
  --square-size: var( --7x )

  --gap: var( --2x )

  --radius: calc( var( --gap ) * 0.42 )

  // colors
  --color-midnight: rgba( 0, 63, 92, 1 )
  --color-midnight-blue: rgba( 73, 107, 138, 1 )
  --color-deep-purple: #58508d
  --color-light-purple: #b7baed
  --color-pink: #bc5090
  --color-light-red: #ff6361
  --color-yellow: #ffa600
  --color-light: rgba( 181, 197, 233, 1 )

  --color-midnight-10x: rgba( 0, 63, 92, 0.1 )
  --color-midnight-20x: rgba( 0, 63, 92, 0.2 )

  --color-transparency-white: rgba( 255, 255, 255, 0.028 )
  --color-transparency-black: rgba( 0, 0, 0, 0.1 )

  // progress bar
  --progress-bar-background: #003f5c
  --progress-value-background: #3776ca
  --progress-value-color: white
