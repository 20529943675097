.placeholder
  flex: 1
  display: flex
  justify-content: center
  align-items: center

  .placeholder-content
    display: flex
    flex-direction: column
    align-items: center

    button
      background: #293647
      font-size: calc( var( --square-size ) * 0.42 )
      padding: 0 calc( var( --gap ) * 0.5 )
      min-height: calc( var( --square-size ) * 0.84 )
