.task-list
  display: flex
  flex-direction: column

  overflow-y: auto

  a
    color: white

  & > .task
    display: flex
    padding: var( --gap )

    .title
      flex: 3

    .assignees
      flex: 1
      display: flex
      justify-content: flex-end
      gap: calc( var( --gap ) * 0.49 )
      flex-wrap: wrap

    &:hover
      background: var( --color-transparency-white )
