.users
  display: flex
  flex-direction: column

  a
    color: white

  .user-list
    flex: 1
    display: flex

    overflow-y: auto

    table
      flex: 1
      display: flex
      flex-direction: column

      thead

        tr
          td
            font-weight: bold

      thead,
      tbody

        tr
          display: flex
          height: var( --square-size )
          align-items: center

          td
            flex: 1
            padding: 0 var( --gap )
            display: flex

            .control
              flex: 1
              display: flex

              div
                background: #293647
                flex: 1
                display: flex

                select
                  flex: 1
                  min-height: var( --square-size )
                  padding: 0 var( --gap )

                button
                  min-width: var( --square-size )
                  display: flex
                  justify-content: center
                  align-items: center

                  &:hover
                    background: var( --color-transparency-white )

            &.actions
              flex: 0
              display: flex

              min-width: var( --square-size )
              padding: 0

              button
                min-width: var( --square-size )
                height: var( --square-size )

                display: flex
                justify-content: center
                align-items: center

                &:hover
                  background: var( --color-transparency-white )

              &.x2
                min-width: calc( var( --square-size ) * 2 )

              &.x3
                min-width: calc( var( --square-size ) * 3 )

              &.x4
                min-width: calc( var( --square-size ) * 4 )

              &.x5
                min-width: calc( var( --square-size ) * 5 )

              &.x6
                min-width: calc( var( --square-size ) * 6 )

            &:nth-child( 1 )
              flex: 2

      tbody
        overflow-y: auto

        tr
          &:nth-child( odd )
            background: var( --color-transparency-black )

          &:hover
            background: var( --color-transparency-white )
