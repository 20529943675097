.login

  a
    color: white
    font-size: calc( var( --square-size ) * 0.35 )

  form
    background: #36455a
    font-size: calc( var( --square-size ) * 0.42 )

    .form-control
      background: #293647

      .icon
        width: var( --square-size )
        display: flex
        justify-content: center
        align-items: center
        font-size: calc( var( --square-size ) * 0.49 )

    input,
    button
      height: var( --square-size )
      padding: 0 var( --gap )

    button
      background: #293647

  .alert
    align-items: center
    gap: calc( var( --gap ) * 0.5 )

    .alert-icon
      font-size: var( --4x )

    &.error
      background: var( --color-light-red )
      color: black

    &.warn
      background: var( --color-yellow )
      color: black
