input,
textarea,
button,
select
  border: none
  background: inherit
  color: inherit

main
  & > section
    form
      font-size: calc( var( --square-size ) * 0.42 )
      display: flex
      flex-direction: column
      gap: var( --gap )

      div

        input,
        textarea,
        select,
        button
          background: #293647
          font-size: calc( var( --square-size ) * 0.42 )
          padding: 0 calc( var( --gap ) * 0.5 )

        input,
        select,
        button
          min-height: calc( var( --square-size ) * 0.84 )

        textarea
          padding: calc( var( --gap ) * 0.35 ) calc( var( --gap ) * 0.5 )

        button
          padding: 0 var( --gap )

        &.assignees
          display: flex
          gap: var( --gap )

          .control
            display: flex
            flex-direction: column

            & > div
              gap: var( --gap )

              button
                padding: 0 var( --gap )
                background: #293647

          .assignees-list
            flex: 2
            display: flex
            flex-wrap: wrap
            align-items: flex-start
            gap: var( --gap )

            button
              background: #293647
              height: var( --square-size )
              padding: 0 var( --gap )
