#root
  background: #3c4b5f
  color: white

  font-size: calc( var( --square-size ) * 0.42 )

  & > aside
    background: rgba( 41, 54, 71, 0.3 )
    color: white

    a
      color: white

    section
      nav
        a
          min-height: var( --square-size )
          display: flex
          align-items: center

          font-size: calc( var( --square-size ) * 0.42 )
          text-decoration: none

          .icon
            display: flex
            justify-content: center
            align-items: center

            height: var( --square-size )
            width: var( --square-size )

            font-size: calc( var( --square-size ) * 0.49 )

          &:hover
            background: var( --color-transparency-white )
            color: var( --color-light-red )

          &.active
            background: var( --color-transparency-white )
            color: var( --color-light-red )

// #root

  aside
    background: var( --color-midnight )
    color: var( --color-light )

    a
      color: inherit

    header
      height: var( --square-size )

      .logo
        height: calc( var( --square-size ) * 0.7 )

    section
      background: var( --color-transparency-white )

      nav

        a
          display: flex
          align-items: center

          gap: calc( var( --gap ) * 0.7 )
          padding: 0 calc( var( --gap ) * 0.7 )

          height: calc( var( --square-size ) * 0.84 )

          font-size: calc( var( --square-size ) * 0.42 )
          text-decoration: none

          svg
            width: calc( var( --square-size ) * 0.49 )
            height: calc( var( --square-size ) * 0.49 )

          &:hover
            background: var( --color-transparency-white )
            color: var( --color-light-red )

          &.active
            background: var( --color-transparency-white )

    footer
      height: var( --square-size )
      background: var( --color-transparency-white )

      button
        height: var( --square-size )
        width: var( --square-size )

        display: flex
        justify-content: center
        align-items: center

        font-size: calc( var( --square-size ) * 0.56 )

        // background: var( --color-transparency-black )
        background: transparent
        color: var( --color-light )

        &:hover
          color: var( --color-light-red )

    & + main

      background: #0e4964
      color: var( --color-light )

      a
        color: var( --color-light )

      header
        min-height: var( --square-size )
        align-items: center

        background: var( --color-transparency-black )
        color: var( --color-light )

        font-size: calc( var( --square-size ) * 0.56 )

        nav
          min-height: unset
          padding: 0 var( --gap )
          & > *
            & + *
              &:before
                content: ">"
                padding-right: var( --gap )

        h1
          padding-left: var( --gap )
          font-weight: bold

        button
          display: flex
          justify-content: center
          align-items: center

          min-height: inherit
          min-width: var( --square-size )

          svg
            font-size: calc( var( --square-size ) * 0.56 )

          background: transparent
          color: var( --color-light )

          &.success
            &:hover
              color: #6fb69a

          &.error
            &:hover
              color: #6fb69a

      nav
        min-height: calc( var( --square-size ) * 0.91 )
        padding: calc( var( --square-size ) * 0.15 ) var( --gap )


        button
          background: transparent
          color: var( --color-light )

          display: flex
          align-items: center
          gap: calc( var( --gap ) * 0.7 )
          font-size: calc( var( --square-size ) * 0.42 )

          &.active
            color: #ea8f98

      section
        font-size: calc( var( --square-size ) * 0.42 )

        form
          input
            background: var(--color-midnight-blue)
            color: var( --color-light )

            height: calc( var( --square-size ) * 0.7 )
            min-height: calc( var( --square-size ) * 0.7 )

            padding: 0 calc( var( --gap ) * 0.7 )

          textarea
            background: var(--color-midnight-blue)
            color: var( --color-light )

            padding: 0 calc( var( --gap ) * 0.7 )
