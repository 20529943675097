.milestones
  & > nav
    height: var( --square-size )
    margin: 0 var( --gap )

    button
      padding: 0 var( --gap )

      font-size: calc( var( --square-size ) * 0.42 )

      display: flex
      align-items: center

      border-bottom: 2px solid transparent

      svg
        margin-right: calc( var( --gap ) * 0.5 )
        font-size: calc( var( --square-size ) * 0.49 )

      &.active
        border-bottom-color: #ea8f98

  & > section
    font-size: calc( var( --square-size ) * 0.42 )

    a
      color: white

    .milestone-item
      font-size: calc( var( --base ) * 2.8 )
      padding: var( --gap )

      button
        background: transparent
        color: var( --color-light )
        padding: calc( var( --base ) * 0.7 )
        font-weight: bold

        &:hover
          background: #25669c

      strong
        font-weight: 600
        color: white

      .milestone-identity
        .dueDate
          font-size: 84%

      .milestone-progress
        gap: calc( var( --base ) * 0.7 )

      &:hover
        background: var( --color-transparency-white )
