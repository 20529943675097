.task-aside
  display: flex
  flex-direction: column
  gap: var( --gap )

  .item
    display: flex
    flex-direction: column
    gap: calc( var( --gap ) * 0.84 )

    .header
      display: flex
      align-items: center

      cursor: pointer

      h1
        flex: 1
        font-weight: bold

    button,
    select,
    input
      background: #293647
      min-height: calc( var( --square-size ) * 0.84 )
      height: calc( var( --square-size ) * 0.84 )
      padding: 0 var( --gap )

    &.assignees

      .control
        select
          width: 100%

      .assignee-list
        display: flex
        gap: calc( var( --gap ) * 0.84 )
        align-items: baseline
        flex-wrap: wrap

        .owner
          width: calc( var( --square-size ) * 0.84 )

    &.effort

      .control
        display: flex
        align-items: center
        gap: calc( var( --gap ) * 0.84 )

    &.tags

      .control
        display: flex
        flex-direction: column
        gap: calc( var( --gap ) * 0.84 )

        .input
          display: flex
          gap: calc( var( --gap ) * 0.84 )

          input
            flex: 1

      .tag-list
        display: flex
        flex-wrap: wrap
        gap: calc( var( --gap ) * 0.84 )

        .tag
          padding: 0 calc( var( --gap ) * 0.56 )
          border-radius: var( --gap )

    &.elapsed-time

      .control
        display: flex
        gap: calc( var( --gap ) * 0.84 )

        .action
          display: flex
          justify-content: center
          align-items: center

      .time
        display: flex
        gap: calc( var( --gap ) * 0.35 )

        span
          display: flex
          gap: calc( var( --gap ) * 0.35 )
